module.exports={
  "_args": [
    [
      {
        "raw": "mermaid@^6.0.0",
        "scope": null,
        "escapedName": "mermaid",
        "name": "mermaid",
        "rawSpec": "^6.0.0",
        "spec": ">=6.0.0 <7.0.0",
        "type": "range"
      },
      "/home/liyawang/project/sciapps/agavedancer"
    ]
  ],
  "_from": "mermaid@>=6.0.0 <7.0.0",
  "_id": "mermaid@6.0.0",
  "_inCache": true,
  "_location": "/mermaid",
  "_nodeVersion": "5.8.0",
  "_npmOperationalInternal": {
    "host": "packages-16-east.internal.npmjs.com",
    "tmp": "tmp/mermaid-6.0.0.tgz_1464542839663_0.0459758376237005"
  },
  "_npmUser": {
    "name": "knsv",
    "email": "knsv@sveido.com"
  },
  "_npmVersion": "3.7.3",
  "_phantomChildren": {
    "escape-string-regexp": "1.0.5"
  },
  "_requested": {
    "raw": "mermaid@^6.0.0",
    "scope": null,
    "escapedName": "mermaid",
    "name": "mermaid",
    "rawSpec": "^6.0.0",
    "spec": ">=6.0.0 <7.0.0",
    "type": "range"
  },
  "_requiredBy": [
    "/"
  ],
  "_resolved": "https://registry.npmjs.org/mermaid/-/mermaid-6.0.0.tgz",
  "_shasum": "eaee78164113a5ee1f26a0f16e2a337b9ac6424e",
  "_shrinkwrap": null,
  "_spec": "mermaid@^6.0.0",
  "_where": "/home/liyawang/project/sciapps/agavedancer",
  "author": {
    "name": "Knut Sveidqvist"
  },
  "bin": {
    "mermaid": "./bin/mermaid.js"
  },
  "bugs": {
    "url": "https://github.com/knsv/mermaid/issues"
  },
  "dependencies": {
    "chalk": "^0.5.1",
    "d3": "3.5.6",
    "dagre": "^0.7.4",
    "dagre-d3": "0.4.10",
    "he": "^0.5.0",
    "lodash": "^4.6.1",
    "minimist": "^1.1.0",
    "mkdirp": "^0.5.0",
    "moment": "^2.9.0",
    "semver": "^4.1.1",
    "which": "^1.0.8"
  },
  "description": "Markdownish syntax for generating flowcharts, sequence diagrams, class diagrams and gantt charts.",
  "devDependencies": {
    "async": "^0.9.0",
    "babel-eslint": "^4.1.3",
    "babelify": "^6.4.0",
    "browserify": "~6.2.0",
    "clone": "^0.2.0",
    "codeclimate-test-reporter": "0.0.4",
    "dateformat": "^1.0.11",
    "dox": "^0.8.0",
    "eslint": "^1.6.0",
    "eslint-watch": "^2.1.2",
    "event-stream": "^3.2.0",
    "foundation": "^4.2.1-1",
    "front-matter": "^0.2.0",
    "gulp": "~3.9.0",
    "gulp-bower": "0.0.10",
    "gulp-browserify": "^0.5.0",
    "gulp-bump": "^0.1.11",
    "gulp-concat": "~2.4.1",
    "gulp-data": "^1.1.1",
    "gulp-dox": "^0.1.6",
    "gulp-ext-replace": "^0.2.0",
    "gulp-filelog": "^0.4.1",
    "gulp-front-matter": "^1.2.3",
    "gulp-hogan": "^1.1.0",
    "gulp-if": "^1.2.5",
    "gulp-insert": "^0.4.0",
    "gulp-istanbul": "^0.4.0",
    "gulp-jasmine": "~2.1.0",
    "gulp-jasmine-browser": "^0.2.3",
    "gulp-jison": "~1.2.0",
    "gulp-jshint": "^1.9.0",
    "gulp-less": "^3.0.1",
    "gulp-livereload": "^3.8.0",
    "gulp-marked": "^1.0.0",
    "gulp-mdvars": "^2.0.0",
    "gulp-qunit": "~1.2.1",
    "gulp-rename": "~1.2.0",
    "gulp-shell": "^0.2.10",
    "gulp-tag-version": "^1.2.1",
    "gulp-uglify": "~1.0.1",
    "gulp-util": "^3.0.7",
    "gulp-vartree": "^2.0.1",
    "hogan.js": "^3.0.2",
    "jasmine": "2.3.2",
    "jasmine-es6": "0.0.18",
    "jison": "github:zaach/jison",
    "jsdom": "^7.0.2",
    "jshint-stylish": "^2.0.1",
    "karma": "^0.13.15",
    "karma-babel-preprocessor": "^6.0.1",
    "karma-browserify": "^4.4.0",
    "karma-jasmine": "^0.3.6",
    "karma-phantomjs-launcher": "^0.2.1",
    "live-server": "^0.9.0",
    "map-stream": "0.0.6",
    "marked": "^0.3.2",
    "mock-browser": "^0.91.34",
    "path": "^0.4.9",
    "phantomjs": "^2.1.3",
    "proxyquire": "^1.7.3",
    "proxyquire-universal": "^1.0.8",
    "proxyquireify": "^3.0.0",
    "require-dir": "^0.3.0",
    "rewire": "^2.1.3",
    "rimraf": "^2.2.8",
    "tape": "^3.0.3",
    "testdom": "^2.0.0",
    "uglifyjs": "^2.4.10",
    "vinyl-source-stream": "^1.1.0",
    "watchify": "^3.6.1"
  },
  "directories": {},
  "dist": {
    "shasum": "eaee78164113a5ee1f26a0f16e2a337b9ac6424e",
    "tarball": "https://registry.npmjs.org/mermaid/-/mermaid-6.0.0.tgz"
  },
  "gitHead": "b8d3b01f64f30fa1dd46f7de160e8f80147010f3",
  "homepage": "https://github.com/knsv/mermaid#readme",
  "keywords": [
    "diagram",
    "markdown",
    "flowchart",
    "sequence diagram",
    "gantt"
  ],
  "license": "MIT",
  "main": "src/mermaid.js",
  "maintainers": [
    {
      "name": "knsv",
      "email": "knsv@sveido.com"
    }
  ],
  "name": "mermaid",
  "optionalDependencies": {},
  "readme": "ERROR: No README data found!",
  "repository": {
    "type": "git",
    "url": "git+https://github.com/knsv/mermaid.git"
  },
  "scripts": {
    "dist": "npm run dist-slim-mermaid && npm run dist-slim-mermaidAPI && npm run dist-mermaid && npm run dist-mermaidAPI",
    "dist-mermaid": "node node_modules/browserify/bin/cmd.js src/mermaid.js  -t babelify  -s mermaid    -o dist/mermaid.js         && cat dist/mermaid.js         | node node_modules/uglifyjs/bin/uglifyjs -mc > dist/mermaid.min.js",
    "dist-mermaid-nomin": "node node_modules/browserify/bin/cmd.js src/mermaid.js  -t babelify  -s mermaid    -o dist/mermaid.js && node bin/mermaid -v -s testgitgraph.mm && testgitgraph.mm.svg",
    "dist-mermaidAPI": "node node_modules/browserify/bin/cmd.js src/mermaidAPI.js -t babelify -s mermaidAPI -o dist/mermaidAPI.js      && cat dist/mermaidAPI.js      | node node_modules/uglifyjs/bin/uglifyjs -mc > dist/mermaidAPI.min.js",
    "dist-slim-mermaid": "node node_modules/browserify/bin/cmd.js src/mermaid.js  -t babelify  -s mermaid    -o dist/mermaid.slim.js    -x d3   && cat dist/mermaid.slim.js    | node node_modules/uglifyjs/bin/uglifyjs -mc > dist/mermaid.slim.min.js",
    "dist-slim-mermaidAPI": "node node_modules/browserify/bin/cmd.js src/mermaidAPI.js -t babelify -s mermaidAPI -o dist/mermaidAPI.slim.js -x d3 && cat dist/mermaidAPI.slim.js | node node_modules/uglifyjs/bin/uglifyjs -mc > dist/mermaidAPI.slim.min.js",
    "doc": "rm -r build;rm -r dist/www;gulp vartree;cp dist/www/all.html ../mermaid-pages/index.html;cp dist/mermaid.js ../mermaid-pages/javascripts/lib;cp dist/mermaid.forest.css ../mermaid-pages/stylesheets",
    "jasmine": "npm run jison &&node node_modules/jasmine-es6/bin/jasmine.js",
    "jison": "gulp jison_legacy",
    "karma": "node node_modules/karma/bin/karma start karma.conf.js --single-run",
    "lint": "node node_modules/eslint/bin/eslint.js src",
    "live": "live-server ./test/examples",
    "pretest": "npm run jison",
    "tape": "node node_modules/tape/bin/tape test/cli_test-*.js",
    "test": "npm run dist && npm run karma && npm run tape",
    "watch": "source  ./scripts/watch.sh"
  },
  "version": "6.0.0"
}
